.button {
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: bold;
  /* margin: 0px; */
  margin-right: 1rem;
}

.button:hover {
  opacity: 0.8;
}

.button.active {
  background: #3e6ae1;
  color: #fff;
}

.button:disabled {
  cursor: not-allowed;
}
