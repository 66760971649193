@keyframes slideIn {
  from {
    bottom: -100px;
  }
  to {
    bottom: 10px;
  }
}

.notification {
  position: fixed;
  bottom: 10px;
  left: 10px;
  color: #000000;
  padding: 10px;
  border: 1px solid;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  animation: slideIn 0.5s ease-in-out;
  opacity: 0.9;
}

.notification button {
  background: none;
  border: none;
  color: #000000;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.notification.red {
  border-color: #b94a48;
  background-color: #f7e1e1;
}

.notification.yellow {
  border-color: #e6b55a;
  background-color: #fdf4d3;
}

.notification.green {
  border-color: #4cae4c;
  background-color: #e6f4e6;
}
