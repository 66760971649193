dialog {
  width: 500px;
  margin: 10rem auto;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 100;
}

dialog .modal_buttom {
  display: flex;
  justify-content: flex-end;
}

dialog h3 {
  display: flex;
  justify-content: center;
}

.update_form ul {
  list-style: none;
  padding: 0;
  flex-direction: column;
  gap: 24px;
  line-height: 32px;
}

.update_form ul li {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.update_form ul li label {
  min-width: 200px;
  padding-right: 16px;
}

.update_form ul li input {
  width: 100%;
  line-height: 1.5;

  border: 1px solid #171717;
  border-radius: 8px;
  height: 20px;
}
