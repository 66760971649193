body {
  font-family: "Courier New", Courier, monospace;
}

body.locked {
  overflow: hidden;
  padding-right: 17px; /* Ширина полосы прокрутки */
}

/* https://snipp.ru/html-css/table-style */

.table {
  width: 100%;
  border: none;
  margin-bottom: 20px;
  border-collapse: collapse;
  margin: 10px 0px 0px 0px;
}
.table thead th {
  font-weight: bold;
  text-align: left;
  border: none;
  padding: 10px 15px;
  background: #d8d8d8;
  font-size: 14px;
}
.table thead tr:first-child th:first-child {
  border-top-left-radius: 10px;
}
.table thead tr:last-child th:first-child {
  border-bottom-left-radius: 10px;
}

.table thead tr:first-child th:last-child {
  border-top-right-radius: 10px;
}
.table thead tr:last-child th:last-child {
  border-bottom-right-radius: 10px;
}

.table tbody td {
  text-align: left;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  vertical-align: top;
}
.table tbody tr:nth-child(even) {
  background: #f3f3f3;
}
.table tbody tr td:first-child {
  border-radius: 8px 0 0 8px;
}
.table tbody tr td:last-child {
  border-radius: 0 8px 8px 0;
}

.table tbody td {
  transition: background-color 0.3s ease;
}
.table tbody td:hover {
  background-color: rgb(158, 158, 158);
}

.table_updated:nth-child(even) {
  background-color: #aef9c5 !important;
}

.table_updated:nth-child(odd) {
  background-color: #d2fddf !important;
}

.login_blocform {
  margin: 10px auto 0;
  display: flex;
  justify-content: center;
  text-align: center;
}

.login_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px; /* Добавлен отступ сверху */
  border: 2px solid #ccc;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
}

.login_form form {
  width: 100%;
}

.control {
  width: calc(100% + 250px); /* Поле ввода стало шире на 300px */
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid #171717;
  border-radius: 10px;
}

input .error {
  border: 1px solid red;
  /* background-color: #d715aa; */
}

.login_form label {
  display: block;
  text-align: right;
  margin-bottom: 5px;
  width: 100%;
}

.login_form button {
  display: block;
  margin-left: auto;
}

.login_form ul {
  list-style: none;
  padding: 0;
  flex-direction: column;
  gap: 24px;
  line-height: 32px;
}

.login_form li {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.login_form label {
  min-width: 200px;
  padding-right: 16px;
}

.login_form input {
  width: 100%;
  line-height: 1.5;

  border: 1px solid #171717;
  border-radius: 8px;
  height: 20px;
}

.forgot_password_button:hover {
  color: #3e6ae1;
  cursor: pointer;
}

.sort th p:hover {
  color: #3e6ae1;
}

.filter th input {
  border: 1px solid #171717;
  border-radius: 10px;
  height: 20px;
  width: 100%;
}

.filter {
  border: 0px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

button:hover {
  background-color: #7391e4;
}

.panel {
  padding: 0px;
  margin: 10px 0px 0px 0px;
}

.panel ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 0px;
  line-height: 0px;
}

.gray_text {
  color: #b8b8b8;
}

.loading-text {
  display: inline-block;
  width: 70px;
  text-align: center;
}
